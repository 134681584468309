import UserTypes from '../../enums/UserTypes';

export default [
  // {
  //   path: '/client/home',
  //   name: 'client-home',
  //   component: () => import('../../pages/client/Home.vue'),
  //   meta: { memberType: UserTypes.CLIENT },
  // },
  {
    path: '/client/wallet',
    name: 'client-wallet',
    component: () => import('../../pages/client/Wallet.vue'),
    meta: { memberType: UserTypes.CLIENT },
  },
  {
    path: '/client/mines',
    name: 'client-mines',
    component: () => import('../../pages/client/Mines.vue'),
    meta: { memberType: UserTypes.CLIENT },
  },
];
