export default {
  '': '',
  ' ': ' ',
  'Account and data on Orange Admin App': '在Orange管理端上的賬號和數據',
  'Add': '新增',
  'Add vip card successfully': '添加卡片成功',
  'Add Shortcut': '添加到桌面',
  'Address': '地址',
  'Agree to': '我已閱讀並同意',
  'All': '所有',
  'All Coupons': '所有優惠券',
  'All Vip Cards': '所有會員卡',
  'Amount': '金額',
  'An exception happened': '發生異常',
  'Apply': '領取',
  'Are you sure to close your account permanently? This will result in the loss of all your data on Orange Wallet.': '您確定要永久註銷賬戶嗎？這將導致您在Orange Wallet的所有數據丟失。',
  'Are you sure to delete this card?': '確定要刪除該卡片？ ',
  'Are you sure to disable this card?': '確定要停用這張卡？ ',
  'Available': '有效',
  'Available Points': '可用積分',
  'Back': '返回',
  'Balance': '餘額',
  'Balance History': '餘額明細',
  'Bonus': '贈送',
  'By File': '導入檔案',
  'By Parameters': '指定參數',
  'Cancel': '取消',
  'Cancel Redeem ': '取消兌換',
  'Cards': '卡片',
  'Card Details': '詳情',
  'Card Holder': '持卡人',
  'Card Image': '圖案',
  'Card Info': '會員卡詳情',
  'Card Name': '卡名',
  'Card Number': '卡號',
  'Card number is duplicated at line ': '卡號重複，所在行號：',
  'Card Number Length': '卡號長度',
  'Card Number Length is too small': '卡號長度太短',
  'Card Number Prefix': '卡號字首',
  "Card Number Prefix can't be longer than Card Number Length": "卡號字首不能比卡號長度更長",
  'Card Number Start From': '卡號起始',
  'Card Quantity': '生成卡片的數量',
  'Card Quantity is too small': '卡片數量太少',
  'Card Search part1': '找到',
  'Card Search part2': '個結果',
  'Card Type': '類別',
  'Cell Number': '手機號碼',
  'Check It Out': '打開看看',
  'Claim Coupon': '去領取',
  'Claim Now': '立即領取',
  'Close': '關閉',
  'Close ': '將',
  'Close Account': '註銷賬號',
  'Copy Link': '複製連結',
  'Confirm': '確認',
  'Confirm to close account': '確定註銷賬號',
  'Congratulations!': '恭喜！',
  'Contact merchant to restore': '如需繼續使用，請聯絡商家',
  'Contact Name': '聯絡人名稱',
  'Coupon': '優惠券',
  'Coupons': '優惠券中心',
  'Coupon and other benefits': '優惠券、積分等權益信息',
  'Coupon can only be applied by members': '優惠券僅限會員領取',
  'Coupon Center': '優惠券中心',
  'Coupon Detail': '優惠券詳情',
  'Coupons available': '可用優惠券',
  'Current Points': '當前積分',
  'Custom': '自訂',
  'Custom Reload': '自訂儲值',
  'Date of Birth': '出生日期',
  'Delete': '刪除',
  'Description': '描述',
  'Detail': '詳細',
  'Disable': '禁用',
  'Disabled': '禁用',
  'Disable successfully': '禁用成功',
  'Description': '描述',
  'Do you agree to applied the free VIP member card': '您是否同意申請免費VIP會員卡',
  'Email': '電子郵件',
  'Enter address': '輸入位址',
  'Enter cell number': '輸入手機號碼',
  'Enter Cellphone Number': '輸入手機號',
  'Enter Code': '輸入驗證碼',
  'Enter email address': '輸入信箱',
  'Enter name': '輸入名字',
  'Enter password': '輸入密碼',
  'Event Details': '活動详情',
  'Exclusive': '獨家的',
  'Expired': '已過期',
  'Expired Date:': '有效期限至：',
  'Expiry Date': '到期日',
  'Expired QR Code': '活動已過期',
  'Explore Merchants': '搜尋商家',
  'Failed to save image': '圖片儲存失敗',
  'Female' : '女',
  'First Name': '名稱',
  'For Sale': '出售',
  'Forgot password': '忘記密碼',
  'Free Apply': '免費領取',
  'Full Size': '全螢幕',
  'Gender': '性別',
  'General': '通用',
  'Get Verification code': '發送驗證碼',
  'Gift Card': '禮品卡',
  'Have an account already': '已有帳戶',
  'Head Image': '頭部影像',
  'Home': '首頁',
  'Image saved successfully': '圖片保存成功',
  'Import': '導入',
  'Import success': '導入成功',
  'In Use': '使用中',
  'Incorrect Code!': '驗證碼錯誤',
  'Incorrect Username/Password': '手機號碼或密碼錯誤',
  'Incorrect! Please try again.': '不正確! 請重試.',
  'Input your answer': '輸入你的回答',
  'Input your phone number': '輸入你的電話號碼',
  'Insufficient Points': '積分不足',
  'Invalid QR Code': '二維碼已失效',
  'Item Code': '商品碼',
  'Item Coupon': '商品券',
  'Last Name': '姓氏',
  'Layout': '佈局',
  'Less': '縮小',
  'Link Copied': '已複製!',
  'Link VIP:': '關聯會員卡：',
  'Link VIP Card': '關聯會員卡',
  'Link Your VIP Card': '關聯你的會員卡',
  'Link_Your_VIP_Card_DESC1': '電話號碼 ',
  'Link_Your_VIP_Card_DESC2': ' 已經在商家註冊成為會員。成功回答一個安全問題，即可關聯你的會員卡。',
  'Link_Your_VIP_Card_With_Phone_DESC': '關聯你的電話號碼與會員卡。為了完成驗證，請選擇一個安全問題並回答。',
  'Load': '載入',
  'Loading...': '正在載入',
  'Location': '店面位置',
  'Login': '登錄',
  'Loginalertpart1': '無法顯示，請',
  'Loginalertpart2': '立即登入',
  'Loginalertpart3': '查看',
  'Logout': '登出',
  'Logo and Name': 'Logo和名稱',
  'Main Merchant': '主店商',
  'Male' : '男',
  'Max Quantity': '最大數量',
  'Member Coupons': '會員優惠券',
  'Member Information': '會員信息',
  'Merchant': '商家',
  'Merchant Group': '店商組',
  'Mine': '我的',
  'More...': '更多...',
  'More Promotions': '更多優惠',
  'My VIP Cards': '我的會員卡',
  'My Wallet': '我的卡包',
  'Name': '名稱',
  'New user': '新用戶',
  'No': '否',
  'No Content': '沒有內容',
  'No Coupon': '沒有任何優惠券',
  'No Data': '無數據',
  'No data created': '沒有可產生的資料',
  'No data in excel file': 'Excel檔案中沒有資料',
  'No Import Data': '沒有導入資料',
  'No Item Coupon': '沒有任何商品券',
  'No Logo, No Name': '無Logo無名稱',
  'No Vip Card': '沒有任何會員卡',
  'Notes': '註釋',
  'Note: The quantity is limited, please check with the staff before using.': '提示: 數量有限，請使用前先向店員確認。',
  'Offline': '下線',
  'Once closing done, all data below will be wiped out:': '賬號註銷後，以下信息將會被清空無法找回:',
  'Online': '上線',
  'Online reload is not currently supported': '暫時不支持線上充值',
  'Only Logo': '僅Logo',
  'Only Name': '僅名稱',
  'Orange Wallet App': '橘子錢包App',
  'OK': '確定',
  'OK2': '好的',
  'Others': '其他',
  'Password': '密碼',
  'Password invalid': '密碼無效',
  'Pay Amount': '消費金額',
  'Pay Cancel': '消費取消',
  'Personal Info': '登記個人資料',
  'Personal Information': '個人資訊',
  'Personal information': '個人資訊',
  'Phone': '電話',
  'Phone number': '電話號碼',
  'Phone Number': '電話號碼',
  'Phone number cannot be empty': '請輸入電話號碼',
  'Phone number invalid':'電話號碼格式錯誤',
  'Place Order': '立即下單',
  'Please go to the merchant counter for reload': '請前往商家櫃檯充值',
  'Please Input Verify Code': '請輸入驗證碼',
  'Please provide an answer': '請輸入答案',
  'Please provide a name': '請輸入姓名',
  'Please provide a valid email': '請輸入有效的電子郵件',
  'Please read and agree to the terms and conditions': '請閱讀並同意《用戶服務協議》',
  'Please scan merchant QR Code': '請掃描商家二維碼',
  'Points': '積分',
  'Points History': '積分記錄',
  'Points Earned': '賺取積分',
  'Points Used': '使用積分',
  'Point Mall': '積分商城',
  'Points Mall': '積分商城',
  'Point Rate(Total to Point Rate)': '積分倍率(消費金額得積分的倍率)',
  'Point Rules': '積分規則',
  'Preset Reload and Bonus': '預設儲值與贈送',
  'Preset part1': '充',
  'Preset part2': '送',
  'Preset part3': '',
  'Redeem': '使用',
  'Redeem2': '兌換',
  'Redeem ': '兌換',
  'Redeemable': '可兌換',
  'Redeemed': '已兌換',
  'Redeemconfirmpart1': '確定兌換 ',
  'Redeemconfirmpart2': '將自動扣除',
  'Redeemconfirmpart3': '積分',
  'Redeem Prompt': '兌換提示',
  'Register': '註冊帳戶',
  'Reload': '儲值',
  'Reload2': '充值',
  'Reload Amount': '充值金額',
  'Reload Cancel': '充值取消',
  'Resendpart1': '',
  'Resendpart2': '秒後可重新發送',
  'Resendpart3': '收不到驗證碼？ ',
  'Resendpart4': '重新發送',
  'Resend': '重新發送',
  'Reset Password': '重設密碼',
  'Rules': '活動規則',
  'Save': '保存',
  'Scan': '掃碼',
  'Scan your card here': '掃描你的會員卡',
  'Search Store': '搜尋商店',
  'Search': '搜尋',
  'seconds': '秒',
  'Security Answer': '答案',
  'Security Question': '安全問題',
  'See All': '查看全部',
  'Select': '選擇',
  'Select Date': '選擇日期',
  'Select file(xlsx) to import': '請導入xlsx檔',
  'Select Gender': '選擇性別',
  'Setup Password': '設定密碼',
  'Share': '分享好友',
  'Sign In': '登錄',
  'Sold': '售出',
  'Something Worng, Please try it later.': '出錯，請稍後再試',
  'Sorry, please keep earning points to unlock products!': '抱歉~ 請繼續賺取積分以解鎖兌換！',
  'Seconds': '秒',
  'Status': '狀態',
  'Store': '商家',
  'Store Detail': '商家詳情',
  'Submit': '提交',
  'Submit Request': '提交請求',
  'System': '系統',
  'Tag': '標籤',
  'Take Out': '外賣',
  'to': '至',
  'terms and conditions': '《用戶服務協議》',
  'The product has been successfully redeemed.': '商品已成功兌換',
  'Transaction Records': '交易記錄',
  'Transactions, reloads and points history': '交易、充值和會員積分記錄',
  'Try Again': '重試',
  'Update status successfully': '更新狀態成功',
  'Update vip card successfully': '更新卡片成功',
  'Used': '已使用',
  'Used / Expired': '已使用/已過期',
  'Username invalid': '使用者名稱無效',
  'Use Now': '立刻使用',
  'Valid': '有效期限',
  'Valid Stores': '適用分店',
  'Valid Until': '有效期限至',
  'Verification Code': '輸入驗證碼',
  'Verification Code is sended to': '驗證碼已發送到',
  'Verification Failed:': '驗證失敗:',
  'Verification Success!': '驗證成功！',
  'Verify': '驗證',
  'Verify Error': '驗證錯誤',
  'Version': '版本號',
  'View Balance': '查看餘額',
  'VIP canceled': '會員已被註銷',
  'VIP canceled, contact merchant to restore': '會員已被註銷，如需繼續使用，請聯絡商家',
  'VIP Card': '會員卡',
  'VIP Level': 'VIP級別',
  'Welcome': '歡迎',
  'Wallet': '卡包',
  "What's the Last name of this account?": "該會員賬號的姓是什麼",
  "What's the first name of this account?": "該會員賬號的名是什麼",
  "What's the VIP number of this account?": "該會員賬號的VIP號是什麼",
  'Worth': '價值',
  'Yes': '是',
  'Your phone number could not be verified. If you have any question, please contact the merchant.': '你的電話號碼無法被驗證，如有疑問，請聯繫商家',
  'Your phone number was successfully linked to virtual VIP card.': '你的電話號碼已經成功關聯到虛擬會員卡',
  'Your virtual VIP card is now linked with physical card.': '虛擬會員卡現已與實體會員卡關聯',
  ' related account': '所綁定的賬號註銷',
  // more translation keys...
}