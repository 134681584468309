<template>
  <IonApp>
    <IonSplitPane content-id="main-content">
      <!-- <Menu /> -->
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </IonSplitPane>
  </IonApp>
</template>

<script>
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
} from '@ionic/vue';

import { defineComponent } from 'vue';
import { App } from '@capacitor/app';
import { Preferences as Storage } from '@capacitor/preferences';
import { TextZoom } from '@capacitor/text-zoom';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
  },
  watch: {
    '$i18n.locale'() {
      this.checkLocale();
    }
  },
  mounted() {
    this.getLastLocale();
    this.checkLocale();
    this.registerBackButtonAction();

    // set font size fixed.
    if (window.Capacitor.platform != 'web') {
      TextZoom.set({
          value: 1.0,
        });
    }
  },
  beforeUnmount() {
    App.removeAllListeners();
  },
  methods: {
    getLastLocale() {
      Storage.get({ key: 'user_language' }).then((v) => {
        if (v.value && ((v.value == 'zh') || (v.value == 'en'))) {
          this.$i18n.locale = v.value;
        }
      });
    },
    checkLocale() {
      const currentLocale = this.$i18n.locale;
      if (currentLocale === 'zh') {
        document.documentElement.classList.add('font-for-zh');
      } else {
        document.documentElement.classList.remove('font-for-zh');
      }
    },
    registerBackButtonAction() {
      App.addListener('backButton', (e) => {
        if (this.$router.options.history.location === '/store-home' || 
            this.$router.options.history.location === '/client/wallet' || 
            this.$router.options.history.location === '/store-search' || 
            this.$router.options.history.location === '/client/mines') {
            // Exit the app if the current location is exactly one of the specified paths.
            App.exitApp();
        } else {
            // Otherwise, go back to the previous page.
        }
      });
    },
  },
});
</script>
